import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useColorMode } from "theme-ui"

import { FaMoon, FaRegMoon } from "react-icons/fa"
import Button from "./button"

import "./menu-style.css"
import { GiHamburgerMenu } from "react-icons/gi"

const Header = ({ siteTitle, menuLinks }) => {
  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }
  const [colorMode, setColorMode] = useColorMode()
  return (
    <header
      style={{
        background: `black`,
        //marginBottom: `1.45rem`,
        display: `inline-block`,
        width: `100%`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <div class="topnav" id="myTopnav">
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
              float: `left`,
            }}
          >
            <h1 className="title-text">{siteTitle}</h1>
          </Link>
          <div class="pages">
            <Link style={{ color: `white `}} to="/covid/">Covid-19</Link>
            <Link style={{ color: `white `}} to="/gallery/">The Gallery</Link>
            <Link style={{ color: `white `}} to="/map/">The Map</Link>
            <Link style={{ color: `white `}} to="/food/">The Food</Link>
            {/*
            <Button variant="floatRight"
              onClick={e => {
                setColorMode(colorMode === 'default' ? 'dark' : 'default')
                //console.log(colorMode)
            }}>
              Dark Mode {colorMode === 'default' ? <FaRegMoon /> : <FaMoon />}
            </Button>
            */}
            <a href="javascript:void(0);" className="icon" onClick={myFunction}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
